<template>
    <div>
        <div class='tab'>
            <nf-breadcrumb :breadList='breadList'></nf-breadcrumb>
            <div class='tabContent'>
                <el-tabs v-model="activeName">
                    <el-tab-pane label="南繁简介" name="0">
                        <ul class='nfjj'>
                            <router-link tag = 'li' :to='{path: "/aboutNanfan/systemIntroduction"}' class='flex'>
                                <div class='flex_length'>国家南繁管理体系简介</div>
                                <span>2018-05-16</span>
                            </router-link>
                             <router-link tag = 'li' :to='{path: "/aboutNanfan/briefIntr"}' class='flex'>
                                <div class='flex_length'>南繁简介及历程</div>
                                <span>2018-05-16</span>
                            </router-link>
                        </ul>
                    </el-tab-pane>
                    <el-tab-pane label="联系方式" name="1">
                        <ul class='nfjj'>
                            <router-link tag = 'li' :to='{path: "/aboutNanfan/contactInfor"}' class='flex'>
                                <div class='flex_length'>国家南繁工作领导小组办公室（海南省南繁管理局）联系方式</div>
                                <span>2013-12-17</span>
                            </router-link>
                        </ul>
                    </el-tab-pane>
                    <el-tab-pane label="南繁微信平台" name="2">
                        <div class='code'>
                            <p>欢迎扫描关注南繁微信平台</p>
                            <div class='codeImg'>
                                <img :src="require('@/assets/images/qrCode/wxCode.png')" alt="">
                                <div>三亚南繁微信平台</div>
                            </div>
                        </div>
                    </el-tab-pane>
<!--                    <el-tab-pane label="南繁app公众版" name="3">-->
<!--                       <div class='code'>-->
<!--                            <p>欢迎扫描下载南繁app公众版</p>-->
<!--                            <div class='flex codeContent'>-->
<!--                                -->
<!--                                <div class='codeImg'>-->
<!--                                    <img :src="require('@/assets/images/qrCode/ios.png')" alt="">-->
<!--                                     <div>IOS平台</div>-->
<!--                                </div>-->

<!--                                 <div class='codeImg mg'>-->
<!--                                   <img :src="require('@/assets/images/qrCode/apk.png')" alt="">-->
<!--                                    <div>ANDROID平台</div>-->
<!--                                 </div>-->

<!--                                &lt;!&ndash; <div class='codeImg'>-->
<!--                                    <img :src="require('@/assets/images/qrCode/ios2wm@2x.png')" alt="">-->
<!--                                    <div class='flex logo'>-->
<!--                                        <img :src="require('@/assets/images/qrCode/logo_ios@3x.png')" alt="">-->
<!--                                        <span>iPhone</span>-->
<!--                                    </div>-->
<!--                                </div> &ndash;&gt;-->
<!--                            </div>-->
<!--                       </div>-->
<!--                    </el-tab-pane>-->
                </el-tabs>
            </div>
        </div>
    </div>
</template>
<script>
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';


export default {
    components: {
        nfBreadcrumb
    },
    data() {
        return {
          startTime: null,
          pageName: "关于南繁(aboutNanfan.vue)",
             activeName: '0',
             breadList: [{
                name: '关于南繁'
            }]
        };
    },
  beforeCreate() {
    this.startTime = performance.now();
    let this_ = this;
    //1.页面停留时间
    window.addEventListener('beforeunload', function() {
      let endTime = performance.now();
      let stayTime = ((endTime - this_.startTime)/1000).toFixed(1);
      console.log("stayTime", stayTime);

      window.SZHN_FIRE_BEAR?.trackEvent({
        key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
        segmentation: {
          stayTime: stayTime,
        }
      })
    });
    //3.页面加载时间
    window.addEventListener('load', function() {
      let endTime = performance.now();
      let loadTime = ((endTime - this_.startTime)/1000).toFixed(1);
      console.log("loadTime", loadTime);
      window.SZHN_FIRE_BEAR?.trackEvent({
        key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
        segmentation: {
          loadTime: loadTime,
        }
      })
    });
  },
  created() {
    //2.用户浏览量
    window.SZHN_FIRE_BEAR?.trackEvent({
      key: this.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
      segmentation: {
        pageViews: 1,
      }
    })
    //4.网络请求状态
    this.trackNetworkRequest("https://nfggy.com");
  },
    mounted() {
    },
    methods: {
      trackNetworkRequest(url) {
        let requestStartTime = performance.now();
        let this_ = this;

        fetch(url)
            .then(response => {
              let requestEndTime = performance.now();
              let requestTime = ((requestEndTime - requestStartTime)/1000).toFixed(3);
              console.log("requestSuccessTime", requestTime);
              window.SZHN_FIRE_BEAR?.trackEvent({
                key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
                segmentation: {
                  requestTime: requestTime,
                  requestStatu: "success",
                  requestResult: response.toString()
                }
              })
            })
            .catch(error=>{
              let requestEndTime = performance.now();
              let requestTime = ((requestEndTime - requestStartTime)/1000).toFixed(3);
              console.log("requestErrorTime", requestTime);

              window.SZHN_FIRE_BEAR?.trackEvent({
                key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
                segmentation: {
                  requestTime: requestTime,
                  requestStatu: "error",
                  requestResult: error.toString()
                }
              })
            })
      },
    }
};
</script>
<style lang="less" scoped>
    /deep/ .el-tabs__item{
        width: 150px;
        height: 35px;
        text-align: center;
        line-height: 35px;
        padding:0;
        font-size:20px;
    }
    /deep/ .el-tabs__nav{
        padding: 10px 0;
    }
    .tabContent{
        width:1200px;
        margin: 0 auto;
        flex:1;
    }
    .nfjj{
        font-size: 18px;
        color:#333;
        margin-bottom:200px;
        li{
            height: 40px;
            line-height: 40px;
            padding:0 30px;
            border-bottom:1px solid #eee;
            cursor: pointer;
        }
        li:hover{
            background-color: #ecf1ff;
        }
        li::before{
            content: '';
            width: 8px;
            height: 8px;
            background-color: #265ae1;
            display: inline-block;
            border-radius: 50%;
            margin-right:30px;
        }
        span{
            font-size:14px;
            color:#999;
        }
    }
    .code{
        color: #333333;
        font-size: 16px;
        margin:85px 0 173px;
        text-align: center;
        p{
            font-size: 16px;
            color:#333;
            margin-bottom:21px;
        }
        .codeImg{
            width: 148px;
            height: 150px;
            background-color: #e5f6fe;
            border-radius: 4px;
            border: solid 1px #dddddd;
            margin:0 auto;
            img{
                width:100px;
                height:100px;
                margin-top:20px;
            }
            div{
                margin-top:3px;
                font-weight: 600;
                font-size: 14px;

            }
            .logo{
                justify-content: center;
                img{
                    width: 21px;
                    height: 24px;
                    margin: 0 5px 0 0;
                }
            }
        }
        .codeContent{
            justify-content: center;
            .codeImg{
                margin:0;

            }
            .codeImg:nth-child(1){
                margin:0 0 0 0;
                margin-left: 10px;
            }
            .mg{
              margin-left: 15px;
            }
        }
    }
</style>
